<template>
  <div class="profile-button" :class="{ 'profile-button--mobile': isMobile }" @click="$emit('click')">
    Ver perfil
  </div>
</template>

<script>
export default {
  name: 'ProfileButton',
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.profile-button {
  border-radius: 5px;
  border: solid 1px #66c3ff;
  color: white;
  padding: 0.2rem 0.8rem;
  font-size: 0.8125rem;
  font-family: Heebo, sans-serif;
  font-weight: 500;
  cursor: pointer;
  width: fit-content;

  &--mobile {
    padding: 0.5rem 0.8rem;
  }
}
</style>
